import React from 'react';

function Footer() {
    return (
        <footer id="footer-ama">
            <div className="row">
                <div className="medium-6 columns">
                    <div className="container">
                        <div className="cell">
                            <a href="https://ama.com.au" target="_blank" rel="noopener noreferrer">
                                <img alt="AMA Logo" className="ama-footer-logo" data-entity-type="file"
                                     width="200"
                                     data-entity-uuid="839a1bde-0667-47e3-8715-5bec168d14e1"
                                     src="https://ama.com.au/sites/default/files/2021-06/ama-footer-logo.png" />
                            </a>
                            <p></p>
                            <p>
                            <small className="copyright-text">© {(new Date().getFullYear())} Australian Medical Association Limited. All rights
                                reserved.
                            </small></p>
                        </div>
                    </div>
                </div>
                <div className="medium-6 columns">
                    <div className="container">
                        <small className="float-right">
                            <a className="footer-link base-links d-inline-block" href="https://ama.com.au/privacy-policy">Privacy Policy</a> |
                            <a className="footer-link d-inline-block" href="https://ama.com.au/contact-ama">Contact AMA</a>
                        </small>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer