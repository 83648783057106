import React from 'react';
import Header from "./Header";

function MemberConfirm() {
    //const values = JSON.parse(localStorage.getItem('profile'));
    const tname = localStorage.getItem('tname');
    const userState = localStorage.getItem('userState');
    const page = localStorage.getItem('page');
    //console.log(values);
    return (
        <div>
            <Header userState = {userState} />
        <section className="login-container" data-equalizer="foo">
            <div className="row collapse">
                <div className="large-12 columns">
                    <div className="">
                        <div className="panel-table">
                            <div className="form-container">
                                <br />
                                <h3>Thank you for joining the AMA</h3>
                                <p>Dear {tname}</p>
                                <div dangerouslySetInnerHTML={{__html: page}}></div>
                                {/*<p>I warmly welcome you as a member of the Australian Medical Association Limited.</p>*/}
                                {/*<p>As a member of AMA your membership provides you with access to many of the resources and benefits available to all AMA members including:</p>*/}
                                {/*<ul>*/}
                                    {/*<li>Representation of your interests by the President of AMSA on AMA Federal Council</li>*/}
                                    {/*<li>High level representation of your interests to state and federal governments</li>*/}
                                    {/*<li>AMA staff dedicated to provide you with advice and support</li>*/}
                                    {/*<li>Free or subsidised entry to AMA events</li>*/}
                                    {/*<li>Careers Advisory Service and discounts as for other members</li>*/}
                                    {/*<li>Hertz, Qantas club and Virgin Lounge discounts</li>*/}
                                    {/*<li>Access to AMA medico-political news</li>*/}
                                {/*</ul>*/}
                                {/*<p>We believe it is important for you to have your say now through the voice of the profession to ensure your future career in medicine reflects your needs.</p>*/}
                                {/*<p>If you have any questions regarding your membership, please visit www.ama.com.au or please contact our friendly Member Services team on 1300 133 655 or memberservices@ama.com.au</p>*/}
                                {/*<p>Thank you for joining AMA. I wish you every success in your studies and look forward to working with you throughout your career.</p>*/}
                                {/*<p><strong>Prof Stephen Robson Test</strong><br />*/}
                                    {/*<strong>AMA President</strong></p>*/}
                                <p><a href="https://ama.com.au" className="button float-right">Return to AMA website</a> </p>
                                <div className="clearfix"></div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        </div>
    );
}

export default MemberConfirm;
