import React from 'react';

function ClearForm() {
    localStorage.removeItem('profile');
    localStorage.removeItem('imisid');
    localStorage.removeItem('rid');
    localStorage.removeItem('tname');
    localStorage.removeItem('page');
    window.location = '/';
    return (
        <section className="login-container" data-equalizer="foo">
        </section>
    );
}

export default ClearForm;
