import React from 'react';
import './App.css';
import Routes from "./Routes";
import './Footer';
import Footer from "./Footer";
import ReactGA from 'react-ga';

function App() {
    //const profile = localStorage.getItem('profile')?JSON.parse(localStorage.getItem('profile')):null;
    ReactGA.initialize('UA-8416876-13');
    ReactGA.pageview(window.location.pathname + window.location.search);

  return (
      <div className="off-canvas-wrapper">
          <div className="off-canvas-content" data-off-canvas-content>
              {/*<div className="row margin-top">*/}
                  {/*<div className="large-6 columns">*/}
                      {/*<header className="App-header">*/}
                          {/*<h1 className='ama-logo'>*/}
                              {/*<a href="https://stage.ama.com.au"><img src="https://stage.ama.com.au/sites/default/files/2020-03/ama-logo.png" alt="AMA Logo" /></a>*/}
                          {/*</h1>*/}

                      {/*</header>*/}
                  {/*</div>*/}
                  {/*<div className="large-6 columns">*/}
                      {/*<h3 className="right float-right align-right text-muted">Becoming a member</h3>*/}
                  {/*</div>*/}
              {/*</div>*/}
              <Routes />
              <Footer/>
          </div>
      </div>
  );
}

export default App;
