import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import axios from 'axios';
import {
    apiurl,
    stateCardType,
    getSimpleListOptions,
    monthList,
    yearList,
    paymentMethod,
    paymentMethodLookup,
    getOptions,
    keyArrayToObjArray
} from "../config";

function PaymentFields(props) {
    const values = props.values;
    const errors = props.errors;
    const touched = props.touched;

    const [jconfig, setConfig] = useState({});
    useEffect(() => {
        const fetchConfig = async () => {
            const result = await axios(apiurl+'/api/user/join/config',);
            setConfig(result.data);
        };
        fetchConfig();
    }, []);
    console.log(jconfig);
    return (
        <div>
            <div className="row">
                <div className="large-4 columns">
                    <label htmlFor="billing">
                        <div>Billing Method <small
                            className="form-error is-visible">{touched.billing && errors.billing}</small></div>
                        <Field name="billing" component="select">
                            <option value="">--Select--</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annual">Annual</option>
                        </Field>
                    </label>
                </div>
            </div>

            {values.billing !== "" && props.profile.CategoryCode!=="" && <div className="row">
                <div className="large-12 columns">
                    <h3>{values.billing} Membership fee is ${jconfig['prices'][props.profile.ChapterCode][props.profile.CategoryCode]?jconfig['prices'][props.profile.ChapterCode][props.profile.CategoryCode][values.billing]:'Unknown'} AUD (GST included)</h3>
                    {/*for {props.profile.CategoryCode} in {props.profile.ChapterCode}*/}
                </div>

                <div className="large-12 columns">
                    <label htmlFor="paymentMethod">
                        <div>Payment Method <small
                            className="form-error is-visible">{touched.paymentMethod && errors.paymentMethod}</small></div>
                        <Field name="paymentMethod" component="select">
                            <option value="">--Select--</option>
                            {getOptions(keyArrayToObjArray(paymentMethod[props.profile.ChapterCode], paymentMethodLookup))}
                        </Field>
                    </label>
                </div>

            </div>
            }

            {values.paymentMethod === "cc" && <div><div className="row">

                <div className="medium-4 columns">
                    <label htmlFor="CardholderName">
                        <div>Card holder name <small
                            className="form-error is-visible">{touched.CardholderName && errors.CardholderName}</small></div>
                        <Field type="text" name="CardholderName" maxLength="16"/>
                    </label>
                </div>
                <div className="medium-4 columns">
                    <label htmlFor="CardType">
                        <div>Card Type <small
                            className="form-error is-visible">{touched.CardType && errors.CardType}</small></div>
                        <Field name="CardType" component="select">
                            <option value="">--Select--</option>
                            {getSimpleListOptions(stateCardType[props.profile.ChapterCode])}
                        </Field>
                    </label>
                </div>
                <div className="medium-4 columns">
                    <label htmlFor="CreditCardNumber">
                        <div>Card number <small
                            className="form-error is-visible">{touched.CreditCardNumber && errors.CreditCardNumber}</small></div>
                        <Field type="text" name="CreditCardNumber" maxLength="16"/>
                    </label>
                </div>
            </div><div className="row">
                <div className="medium-4 columns">
                    <label htmlFor="ExpiryMonth">
                        <div>Expiry - Month <small
                            className="form-error is-visible">{touched.ExpiryMonth && errors.ExpiryMonth}</small></div>
                        <Field name="ExpiryMonth" component="select">
                            <option value="">--Select--</option>
                            {getOptions(monthList)}
                        </Field>
                    </label>
                </div>

                <div className="medium-4 columns">
                    <label htmlFor="ExpiryYear">
                        <div>Expiry - Year <small
                            className="form-error is-visible">{touched.ExpiryYear && errors.ExpiryYear}</small></div>
                        <Field name="ExpiryYear" component="select">
                            <option value="">--Select--</option>
                            {getSimpleListOptions(yearList)}
                        </Field>
                    </label>
                </div>

                <div className="medium-4 columns">
                    <label htmlFor="CCV">
                        <div>CCV <small
                            className="form-error is-visible">{touched.CCV && errors.CCV}</small></div>
                        <Field type="text" name="CCV" maxLength="4"/>
                    </label>
                </div>
            </div></div> }

            {values.paymentMethod === "bpay" && <div className="row"><div className="medium-12 columns">
                <h3>BPay</h3><p>BPay biller code is XXX and BPAY Reference Number is </p>
            </div> </div>}

            {values.paymentMethod === "payroll" && <div className="row"><div className="medium-12 columns">
                <h3>Payroll deduction for QLD Health</h3><p>Please enter details below</p>
            </div> </div>}

        </div>
    );
}

export default PaymentFields;