import React, {useEffect} from 'react';
import { Formik, Field } from 'formik';
import * as Constants from './config';
import axios from 'axios';
import {useHistory, NavLink} from 'react-router-dom';
import PaymentFields from "./signup/PaymentFields";
import Header from "./Header";
import NameHeader from "./signup/NameHeader";

function MemberPayment() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const imisid = localStorage.getItem('imisid')?localStorage.getItem('imisid'):0;
    const history = useHistory();
    console.log(profile);
    if (profile === null || imisid === null || imisid === 'null' || imisid === 0) {
        window.location = '/';
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    //const isDoctor = profile.stage==='dit' || profile.stage==='gp' || profile.stage==='sp';
    return (
        <div>
            <Header userState = {profile.ChapterCode} />
        <section className="login-container" data-equalizer="foo">
            <div className="row collapse">
                <div className="large-12 columns">
                    <div className="feeslist-subscribers ">
                        <NameHeader profile={profile} imisid={imisid} />

                        <div className="panel-table">
                            <div className="form-container">
                                <div className="row">
                                    <div className="medium-12 columns">
                                        <h3>Step 3: Membership Payment</h3>
                                    </div>
                                </div>
                                <Formik
                                    initialValues={{
                                        billing: profile.billing?profile.billing:'',
                                        paymentMethod: profile.paymentMethod?profile.paymentMethod:'',
                                        CardType: '',
                                        CardholderName: '',
                                        CreditCardNumber: '',
                                        ExpiryMonth: '',
                                        ExpiryYear: '',
                                        CCV: '',
                                        accept: 0
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validate={values => {
                                        let errors = {};
                                        if (!values.billing) {errors.billing = 'Required';}
                                        if (!values.paymentMethod) {errors.paymentMethod = 'Required';}
                                        if (values.accept ===0) {errors.accept = 'You must confirm and accept the conditions above';}
                                        if (values.paymentMethod === 'cc') {
                                            if (!values.CardType) {errors.CardType = 'Required';}
                                            if (!values.CardholderName) {errors.CardholderName = 'Required';}
                                            if (!values.CreditCardNumber) {errors.CreditCardNumber = 'Required';}
                                            if (!values.ExpiryMonth) {errors.ExpiryMonth = 'Required';}
                                            if (!values.ExpiryYear) {errors.ExpiryYear = 'Required';}
                                            if (!values.CCV) {errors.CCV = 'Required';}
                                            let dd = new Date(values.ExpiryYear+'-'+values.ExpiryMonth+'-30');
                                            //console.log(dd);
                                            let now = new Date();
                                            let timeDiff = now.getTime() - dd.getTime();
                                            //console.log(timeDiff);
                                            if (timeDiff > 0) {
                                                errors.ExpiryMonth = 'Card expiry is invalid';
                                            }
                                        }
                                        //
                                        //if (!values.Lastname) {errors.Lastname = 'Required';}
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        let errors = {};
                                        let mdata = Object.assign(profile, values);
                                        mdata.iMISID = localStorage.getItem('imisid')?localStorage.getItem('imisid'):0;
                                        mdata.rid = localStorage.getItem('rid')?localStorage.getItem('rid'):0;
                                        //mdata.business = {};
                                        //mdata.business = (({ address1, address2, address3, suburb, state, postcode, country }) => ({ address1, address2, address3, suburb, state, postcode, country }))(values);
                                        console.log(JSON.stringify(mdata, null, 2));
                                        localStorage.setItem('profile', JSON.stringify(mdata, null, 2));

                                        axios.post(Constants.apiurl+'/api/user/join/post-pay', {mdata})
                                            .then(res=>{
                                                console.log('getting sth');
                                                console.log(res);
                                                console.log(res.data);

                                                if (res.data.success) {
                                                    localStorage.setItem('tname', profile.Firstname);
                                                    localStorage.setItem('userState', profile.ChapterCode);
                                                    localStorage.setItem('page', res.data.page);
                                                    localStorage.removeItem('profile');
                                                    localStorage.removeItem('rid');
                                                    localStorage.removeItem('imisid');
                                                    //window.location = '/join-member/confirm';
                                                    history.push('/join-member/confirm');
                                                } else {
                                                    errors.VALIDATION = res.data.message;
                                                    setErrors(errors);
                                                }
                                                setSubmitting(false);
                                            })
                                            .catch(()=>{
                                                errors.VALIDATION = 'Error occurred, please contact member services and quote the membership ID on the top right.';
                                                console.log("blocked?");
                                                setErrors(errors);
                                                setSubmitting(false);
                                            })

                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          /* and other goodies */
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">

                                            </div>
                                            <PaymentFields
                                                errors = {errors}
                                                touched = {touched}
                                                values = {values}
                                                profile = {profile}
                                                handleChange = {handleChange}
                                                handleBlur = {handleBlur}
                                                handleSubmit = {handleSubmit}
                                                isSubmitting = {isSubmitting}
                                            />

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <h2>Declaration</h2>
                                                    <p><strong>I hereby apply to be an elected member of the Australian Medical Association Limited and agree, if elected, to abide by the <a href="https://ama.com.au/position-statement/code-ethics-2004-editorially-revised-2006-revised-2016" target="_blank">AMA Code of Ethics</a> and the Rules of the AMA.</strong></p>
                                                    {profile.ChapterCode ==='ACT' &&
                                                    <p><strong><i>PLEASE NOTE: The AMA (ACT) reserves the right to decline assistance or provide assistance on a conditional or other basis to new members. In particular, AMA (ACT) may exercise its discretion in cases where a member requests assistance in relation to an issue which was known to the member prior to joining AMA (ACT).  If the AMA (ACT) exercises its discretion to provide assistance, it may request the payment of an additional fee-for-service having regard to the individual circumstances of a new member.<br/>
                                                        NOTE: Doctors In Training are advised that 'Fee For Service' arrangements does not apply in Post Graduate years 1-5 inclusive. The additional fee-for service will be applied in accordance with the AMA (ACT)’s Fee for Service policy. This policy can be viewed on <a href="https://act.ama.com.au/advocacy/fee-for-service" target="_blank">AMA (ACT)’s website</a></i></strong></p>}
                                                    <p>Your subscription is subject to approval by the AMA Board of your nominated State. Upon approval you will receive a membership pack. Any application not approved by the Board will be notified in due course and your payment will be refunded. The Australian Medical Association Limited and state AMA entities comply with the Privacy Act 1988. Please refer to the AMA Privacy Policy to understand our commitment to you and information on how we store and protect your data.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="medium-12 columns">
                                                    <label>
                                                        <Field type="checkbox" name="accept" /> I have read the above information and I accept the conditions for joining the AMA
                                                    </label>
                                                    <small
                                                        className="form-error is-visible">{touched.accept && errors.accept}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <small className="form-error is-visible">{errors.VALIDATION}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-6 columns">
                                                    <NavLink to="/join-member/next" className="button expanded success">
                                                        Back
                                                    </NavLink>
                                                </div>
                                                <div className="large-6 columns">
                                                    <button type="submit" className="button expanded success" disabled={isSubmitting}>
                                                        {!isSubmitting && <span>Confirm and submit</span>}
                                                        {isSubmitting && <i className='material-icons spin'>autorenew</i>}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <div className="row margin-top">
                <div className="large-12 columns">
                    <a href="/clear" className="button">Clear Form</a>
                </div>
            </div>
        </div>
    );
}

export default MemberPayment;
