import React from 'react';
import * as Constants from "../config";

function NameHeader(props) {
    const profile = props.profile;
    return (
        <div className="row">
            <div className="medium-12 columns">
                {profile.Lastname && props.imisid && <div className="text-center">
                    <h3><strong>{profile.Prefix} {profile.Lastname}</strong> - {Constants.careerStageLookup[profile.ChapterCode][profile.stage]}</h3>
                    <p className="float-right">Membership ID: <strong>{props.imisid}</strong></p>
                    <div className="clearfix"></div>
                </div>
                }
                {!profile.Lastname && !props.imisid && <div className="text-center">
                    <h2>New Member</h2>
                </div>
                }

            </div>
        </div>
    );
}

export default NameHeader;
