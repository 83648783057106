import React from "react";
import { stateDetails } from "./config";
import { NavLink } from "react-router-dom";

function Header(props) {
  let ChapterCode = props.userState;
  if (ChapterCode == "WA") {
    ChapterCode = "";
  }
  return (
    <div className="row margin-top">
      <div className="large-6 columns">
        <header className="App-header">
          <h1 className="ama-logo">
            <NavLink to="/">
              <img
                src="https://ama.com.au/sites/default/files/2020-03/ama-logo.png"
                alt="AMA Logo"
              />
            </NavLink>
          </h1>
        </header>
      </div>
      <div className="large-6 columns">
        <h3 className="right float-right align-right text-muted">
          Become a Member
        </h3>
        {ChapterCode && (
          <small className="float-right">
            If you are having trouble, please contact {ChapterCode} member
            services on {stateDetails[ChapterCode]["phone"]}
          </small>
        )}
        {ChapterCode == "NT" && (
          <p class="float-right text-right">
            <b>
              If you are joining as a member of AMA NT you automatically receive
              free membership to ASMOF NT.
            </b>
          </p>
        )}
      </div>
    </div>
  );
}

export default Header;
