import React from 'react';
import { Field } from 'formik';
import {getOptions, keyArrayToObjArray} from '../config';
import * as Constants from '../config';
import {prefixByCareer} from "../config";

function PersonalInfo(props) {
    const values = props.values;
    //const ChapterCode = props.values.ChapterCode;
    //const stage = props.values.stage;
    const errors = props.errors;
    const touched = props.touched;
    return (
        <div>
            <div className="row">
                <div className="large-12 columns">
                    <h3>Personal Information</h3>
                </div>
                {values.stage !== '' &&
                <div className="large-3 columns">
                    <label htmlFor="Prefix">
                        <div>Prefix <small
                                className="form-error is-visible">{errors.Prefix && touched.Prefix && errors.Prefix}</small>
                        </div>
                        <Field name="Prefix" component="select">
                            <option value="">--Select--</option>
                            {getOptions(keyArrayToObjArray(prefixByCareer[values.stage], Constants.prefixLookup))}
                        </Field>
                    </label>
                </div>
                }
                <div className="large-3 columns">
                    <label htmlFor="Firstname">
                        <div>First Name <small
                            className="form-error is-visible">{errors.Firstname && touched.Firstname && errors.Firstname}</small>
                        </div>
                        <Field type="text" name="Firstname"/>
                    </label>
                </div>
                <div className="large-3 columns">
                    <label htmlFor="MiddleName">
                        <div>Middle Name</div>
                        <Field type="text" name="MiddleName"/>
                    </label>
                </div>
                <div className="large-3 columns">
                    <label htmlFor="Lastname">
                        <div>Last Name <small
                            className="form-error is-visible">{errors.Lastname && touched.Lastname && errors.Lastname}</small>
                        </div>
                        <Field type="text" name="Lastname"/>
                    </label>

                </div>
            </div>

            <div className="row">
                <div className="large-4 columns">
                    <label htmlFor="DateOfBirth">
                        <div>Date of birth <small
                            className="form-error is-visible">{errors.DateOfBirth && touched.DateOfBirth && errors.DateOfBirth}</small>
                        </div>
                        <Field type="date" name="DateOfBirth" placeholder="DD/MM/YYYY" />
                    </label>
                </div>
                <div className="large-4 columns">
                    <label>Email <small
                        className="form-error is-visible">{errors.EmailAddress && touched.EmailAddress && errors.EmailAddress}</small>
                    </label>
                    <Field type="email" name="EmailAddress"
                           onChange={(e) => {
                               // if (values.Username === '') {
                                   props.setFieldValue('Username', e.target.value);
                               //}
                               props.setFieldValue('EmailAddress', e.target.value);
                           }
                           }
                    />
                </div>
                <div className="large-4 columns">
                    <label htmlFor="MobilePhone">
                        <div>Mobile <small
                            className="form-error is-visible">{errors.MobilePhone && touched.MobilePhone && errors.MobilePhone}</small></div>
                        <Field type="text" name="MobilePhone" maxLength="10"/>
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="large-4 columns">
                    <label htmlFor="Gender">
                        <div>Gender</div>
                        <Field name="Gender" component="select">
                            <option value="">--Select--</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="X">Non-binary</option>
                            <option value="T">Different term</option>
                            <option value="Z">Prefer not to answer</option>
                        </Field>
                    </label>
                </div>
                <div className="large-8 columns">
                    <label htmlFor="ATSI">
                        <div>Are you of Aboriginal and/or Torres Strait Islander
                            origin?
                        </div>
                        <Field name="ATSI" component="select">
                            <option value="">--Select--</option>
                            <option value="NON_DISCLOSURE">Do not disclose</option>
                            <option value="No">No</option>
                            <option value="YES_ABORIGINAL">Yes, Aboriginal</option>
                            <option value="YES_TSI">Yes, Torres Strait Islander</option>
                            <option value="YES_ATSI">Yes, both Aboriginal and Torres Strait Islander</option>
                        </Field>
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="large-12 columns">
                    <h3>Account details</h3>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="Username">
                        <div>Username <small
                            className="form-error is-visible">{touched.Username && errors.Username}</small></div>
                        <Field type="text" name="Username" maxLength="32"/>
                    </label>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="Password">
                        <div>Password <small
                            className="form-error is-visible">{touched.Password && errors.Password}</small></div>
                        <Field type="password" name="Password" maxLength="32"/>
                    </label>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="Password_confirm">
                        <div>Confirm Password <small
                            className="form-error is-visible">{touched.Password_confirm && errors.Password_confirm}</small></div>
                        <Field type="password" name="Password_confirm" maxLength="32"/>
                    </label>
                </div>
            </div>
        </div>

    );
}

export default PersonalInfo;