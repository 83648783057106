import React from "react";
import { Route, Switch } from "react-router-dom";
import SignupMember from "./containers/SignupMember";
import StudentConfirm from "./containers/StudentConfirm";
import MemberConfirm from "./containers/MemberConfirm";
import MemberDetails from "./containers/MemberDetails";
import MemberPayment from "./containers/MemberPayment";
import ClearForm from "./containers/ClearForm";
//import Home from "./containers/Home";
//import ForgotPassword from "./containers/ForgotPassword";

export default () =>
    <Switch>
        <Route path="/" exact component={SignupMember} />
        <Route path="/join-member" exact component={SignupMember} />
        <Route path="/join-member/next" exact component={MemberDetails} />
        <Route path="/join-member/payment" exact component={MemberPayment} />
        <Route path="/join-member/confirm" exact component={MemberConfirm} />
        <Route path="/join-student/confirm" exact component={StudentConfirm} />
        {/*<Route path="/join-test" exact component={SignupTest} />*/}
        {/*<Route path="/forgot-password" exact component={ForgotPassword} />*/}
        <Route path="/clear" exact component={ClearForm} />
    </Switch>;