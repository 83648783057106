import React, { useEffect } from "react";
import { Formik, Field } from "formik";
import * as Constants from "./config";
import { getOptions, keyArrayToObjArray } from "./config";
import AddressFields from "./signup/AddressFields";
import { useHistory, NavLink } from "react-router-dom";
import Header from "./Header";
import NameHeader from "./signup/NameHeader";
//import {getSimpleListOptions} from "./config";

function MemberDetails() {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const imisid = localStorage.getItem("imisid")
    ? localStorage.getItem("imisid")
    : 0;
  //const rid = localStorage.getItem('rid')?localStorage.getItem('rid'):0;
  //console.log(profile);
  if (
    profile === null ||
    imisid === null ||
    imisid === "null" ||
    imisid === 0
  ) {
    window.location = "/clear";
  }
  const isDoctor =
    profile.stage === "dit" || profile.stage === "gp" || profile.stage === "dr";
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header userState={profile.ChapterCode} />
      <section className="login-container" data-equalizer="foo">
        <div className="row collapse">
          <div className="large-12 columns">
            <div className="feeslist-subscribers ">
              <NameHeader profile={profile} imisid={imisid} />

              <div className="panel-table">
                <div className="form-container">
                  <div className="row">
                    <div className="medium-12 columns">
                      <h3>Step 2: Membership Details</h3>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      AHPRA: profile.AHPRA ? profile.AHPRA : "",
                      CategoryCode: profile.CategoryCode
                        ? profile.CategoryCode
                        : "",
                      CRAFT_GROUP: profile.CRAFT_GROUP
                        ? profile.CRAFT_GROUP
                        : "",
                      reason: profile.reason ? profile.reason : "",
                      CompanyName: profile.CompanyName
                        ? profile.CompanyName
                        : "",
                      FED_PRACTICE_GROUP: profile.FED_PRACTICE_GROUP,
                      //address1: profile.business?profile.business.address1:'',
                      //address2: profile.business?profile.business.address2:'',
                      //address3: profile.business?profile.business.address3:'',
                      //suburb: profile.business?profile.business.suburb:'',
                      //state: profile.business?profile.business.state:profile.ChapterCode,
                      //postcode: profile.business?profile.business.postcode:'',
                      business: {
                        address1: profile.business
                          ? profile.business.address1
                          : "",
                        address2: profile.business
                          ? profile.business.address2
                          : "",
                        address3: profile.business
                          ? profile.business.address3
                          : "",
                        suburb: profile.business ? profile.business.suburb : "",
                        state: profile.business
                          ? profile.business.state
                          : profile.ChapterCode,
                        postcode: profile.business
                          ? profile.business.postcode
                          : "",
                      },
                      home: {
                        address1: profile.home ? profile.home.address1 : "",
                        address2: profile.home ? profile.home.address2 : "",
                        address3: profile.home ? profile.home.address3 : "",
                        suburb: profile.home ? profile.home.suburb : "",
                        state: profile.home
                          ? profile.home.state
                          : profile.ChapterCode,
                        postcode: profile.home ? profile.home.postcode : "",
                      },
                      preferred: profile.preferred ? profile.preferred : "Home",
                      referral: profile.referral ? profile.referral : "",
                      asmof: profile.asmof ? profile.asmof : false,
                      wpissue: profile.wpissue ? profile.wpissue : "",
                      gradLocation: profile.gradLocation
                        ? profile.gradLocation
                        : "",
                      gradCountry: profile.gradCountry
                        ? profile.gradCountry
                        : "",
                      privatePractice: profile.privatePractice
                        ? profile.privatePractice
                        : "",
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validate={(values) => {
                      let errors = {};
                      if (profile.stage === "dr") {
                        if (!values.CRAFT_GROUP) {
                          errors.CRAFT_GROUP = "Required";
                        }
                      }
                      if (values.preferred === "Home") {
                        if (
                          !values.home.address1 ||
                          !values.home.state ||
                          !values.home.suburb ||
                          !values.home.postcode
                        ) {
                          errors.home = {};
                        }
                        if (!values.home.address1) {
                          errors.home.address1 = "Required";
                        }
                        if (!values.home.state) {
                          errors.home.state = "Required";
                        }
                        if (!values.home.suburb) {
                          errors.home.suburb = "Required";
                        }
                        if (!values.home.postcode) {
                          errors.home.postcode = "Required";
                        }
                      } else {
                        if (
                          !values.business.address1 ||
                          !values.business.state ||
                          !values.business.suburb ||
                          !values.business.postcode
                        ) {
                          errors.business = {};
                        }
                        if (!values.business.address1) {
                          errors.business.address1 = "Required";
                        }
                        if (!values.business.state) {
                          errors.business.state = "Required";
                        }
                        if (!values.business.suburb) {
                          errors.business.suburb = "Required";
                        }
                        if (!values.business.postcode) {
                          errors.business.postcode = "Required";
                        }
                      }
                      if (!values.CategoryCode) {
                        errors.CategoryCode = "Required";
                      }

                      if (
                        Constants.wpissueState.indexOf(profile.ChapterCode) >=
                          0 &&
                        !values.wpissue
                      ) {
                        errors.wpissue = "Required";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        let tmp = Object.assign(profile, values);
                        if (profile.stage === "dit") {
                          tmp.CRAFT_GROUP = "DIT";
                        }
                        if (
                          Constants.asmofState.indexOf(profile.ChapterCode) >=
                            0 &&
                          (profile.stage === "dit" ||
                            (profile.stage === "dr" &&
                              values.CRAFT_GROUP !== "GENP"))
                        ) {
                          tmp.asmof = !values.asmof;
                        }
                        //console.log(values);
                        //tmp.business = {};
                        //tmp.business = (({ address1, address2, address3, suburb, state, postcode, country }) => ({ address1, address2, address3, suburb, state, postcode, country }))(values);

                        console.log(JSON.stringify(tmp, null, 2));
                        setSubmitting(false);
                        localStorage.setItem(
                          "profile",
                          JSON.stringify(tmp, null, 2)
                        );
                        history.push("/join-member/payment");
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {isDoctor && (
                            <div className="medium-6 columns">
                              <label htmlFor="AHPRA">
                                <div>AHPRA ID (Recommended)</div>
                                <Field
                                  type="text"
                                  name="AHPRA"
                                  maxLength="13"
                                  placeholder="MED"
                                />
                              </label>
                            </div>
                          )}

                          <div className="medium-6 columns">
                            <label htmlFor="CategoryCode">
                              <div>
                                AMA Membership Type{" "}
                                <small className="form-error is-visible">
                                  {touched.CategoryCode && errors.CategoryCode}
                                </small>
                              </div>
                              <Field name="CategoryCode" component="select">
                                <option value={null}>Select</option>
                                {getOptions(
                                  keyArrayToObjArray(
                                    Constants.membershipCategories[
                                      profile.ChapterCode
                                    ][profile.stage],
                                    Constants.membershipCategoryLookup
                                  )
                                )}
                              </Field>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="medium-4 columns">
                            <label>Federal practice group</label>
                            <label>
                              <Field
                                type="checkbox"
                                name="FED_PRACTICE_GROUP"
                                value="DIT"
                              />{" "}
                              Doctor in Training
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="FED_PRACTICE_GROUP"
                                value="GP"
                              />{" "}
                              General Practitioner
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="FED_PRACTICE_GROUP"
                                value="PSP"
                              />{" "}
                              Private Specialist Practice
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="FED_PRACTICE_GROUP"
                                value="PHD"
                              />{" "}
                              Public Hospital Doctor
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="FED_PRACTICE_GROUP"
                                value="RD"
                              />{" "}
                              Rural doctor
                            </label>
                          </div>

                          {profile.stage === "dr" && (
                            <div className="medium-4 columns">
                              <label htmlFor="CRAFT_GROUP">
                                <div>
                                  Specialty{" "}
                                  <small className="form-error is-visible">
                                    {touched.CRAFT_GROUP && errors.CRAFT_GROUP}
                                  </small>
                                </div>
                                <Field name="CRAFT_GROUP" component="select">
                                  {getOptions(Constants.craftGroup)}
                                </Field>
                              </label>
                            </div>
                          )}

                          <div className="medium-4 columns">
                            <label htmlFor="reason">
                              <div>Reason For Joining</div>
                              <Field name="reason" component="select">
                                <option value={null}>Select</option>
                                <option value="BENEFITS">
                                  Access commercial partner benefits
                                </option>
                                <option value="BELONG">
                                  Join the peak medical association
                                </option>
                                <option value="LOBBYING">
                                  Lobbying health care policy
                                </option>
                                <option value="PROF">
                                  Access CPD learning, tracking and professional
                                  resources
                                </option>
                                <option value="CAREER">
                                  Access personalised career support
                                </option>
                                <option value="IR">
                                  Access workplace and industrial relations
                                  support
                                </option>
                                <option value="OTHER">
                                  Purchase AMA Membership promotional offer
                                </option>
                              </Field>
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="large-6 columns">
                            <label htmlFor="gradLocation">
                              <div>
                                Did you graduate from your medical degree
                                outside of Australia or New Zealand?
                              </div>
                              <Field name="gradLocation" component="select">
                                <option value={null}>Select</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                                <option value="NA">Prefer not to answer</option>
                              </Field>
                            </label>
                          </div>
                          {values.gradLocation === "YES" && (
                            <div className="large-6 columns">
                              <label htmlFor="gradCountry">
                                <div>Which country?</div>
                                <Field name="gradCountry" component="select">
                                  <option value={null}>Select</option>
                                  {getOptions(Constants.countries)}
                                </Field>
                              </label>
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="large-12 columns">
                            <h3>Business details</h3>
                          </div>
                          <div className="large-12 columns">
                            <label htmlFor="CompanyName">
                              <div>Practice or hospital name</div>
                              <Field type="text" name="CompanyName" />
                            </label>
                          </div>
                        </div>

                        <AddressFields
                          type="business"
                          errors={errors}
                          touched={touched}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          handleSubmit={handleSubmit}
                          isSubmitting={isSubmitting}
                        />

                        <div className="row">
                          <div className="large-12 columns">
                            <h3>Home address</h3>
                          </div>
                        </div>
                        <AddressFields
                          type="home"
                          errors={errors}
                          touched={touched}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          handleSubmit={handleSubmit}
                          isSubmitting={isSubmitting}
                        />

                        <div className="row">
                          <div className="medium-12 columns">
                            <label htmlFor="preferred">
                              <div>Preferred contact address</div>
                              <Field name="preferred" component="select">
                                <option value="Home">Home</option>
                                <option value="Business">Business</option>
                              </Field>
                            </label>
                          </div>
                        </div>

                        {profile.ChapterCode === "QLD" &&
                          profile.stage === "dr" &&
                          values.CRAFT_GROUP === "GENP" && (
                            <div className="row">
                              <div className="large-12 columns">
                                <h3>ASMOF</h3>
                              </div>
                              <div className="medium-12 columns">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name="asmof"
                                    checked={values.asmof}
                                  />
                                  <strong>
                                    Opt in. If you wish to opt in and become a
                                    member of the Australian Salaried Medical
                                    Officers’ Federation Queensland, Industrial
                                    Organisation of Employees (ASMOFQ) and the
                                    Australian Salaried Medical Officers
                                    Federation Queensland Branch (ASMOF QB),
                                    please tick this box.
                                  </strong>
                                </label>
                                <p>
                                  <strong>
                                    Your membership with AMA Queensland also
                                    includes membership with ASMOFQ and ASMOF QB
                                    for no extra fee.
                                    <br />
                                    By completing this online application you
                                    agree to abide by the rules and policies of
                                    ASMOFQ and ASMOF QB as amended from time to
                                    time.
                                  </strong>
                                </p>
                              </div>
                            </div>
                          )}

                        {profile.ChapterCode === "QLD" &&
                          profile.stage === "dr" &&
                          values.CRAFT_GROUP !== "GENP" && (
                            <div className="row">
                              <div className="large-12 columns">
                                <h3>ASMOF</h3>
                              </div>
                              <div className="medium-12 columns">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name="asmof"
                                    checked={values.asmof}
                                  />
                                  <strong>
                                    Opt out. If you wish to opt out and not
                                    become a member of the Australian Salaried
                                    Medical Officers’ Federation Queensland,
                                    Industrial Organisation of Employees
                                    (ASMOFQ) and the Australian Salaried Medical
                                    Officers Federation Queensland Branch (ASMOF
                                    QB), please tick this box.
                                  </strong>
                                </label>
                                <p>
                                  <strong>
                                    Your membership with AMA Queensland also
                                    includes membership with ASMOFQ and ASMOF QB
                                    for no extra fee.
                                    <br />
                                    By completing this online application you
                                    agree to abide by the rules and policies of
                                    ASMOFQ and ASMOF QB as amended from time to
                                    time.
                                  </strong>
                                </p>
                              </div>
                            </div>
                          )}

                        {/* QLD Private Practice Owner */}
                        {profile.ChapterCode === "QLD" &&
                          profile.stage === "dr" && (
                            <div className="row">
                              <div className="large-12 columns">
                                <h3>Private Practice Owners</h3>
                              </div>
                              <div className="medium-12 columns">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name="privatePractice"
                                    checked={values.privatePractice}
                                  />
                                  <strong>
                                    I am a private practice owner.
                                  </strong>
                                </label>
                              </div>
                            </div>
                          )}

                        {((profile.ChapterCode === "NT" &&
                          profile.stage === "dit") ||
                          (profile.ChapterCode === "NT" &&
                            profile.stage === "dr" &&
                            values.CRAFT_GROUP !== "GENP")) && (
                          <div className="row">
                            <div className="large-12 columns">
                              <h3>ASMOF</h3>
                            </div>
                            <div className="medium-12 columns">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="asmof"
                                  checked={values.asmof}
                                />
                                <strong>
                                  Opt out. If you wish to opt out and not become
                                  a member of the Australian Salaried Medical
                                  Officers’ Federation Northern Territory (ASMOF
                                  NT), please tick this box.
                                </strong>
                              </label>
                              <p>
                                <strong>
                                  Your membership with AMA NT also includes
                                  membership with ASMOF NT for no extra fee.
                                  <br />
                                  By completing this online application you
                                  agree to abide by the rules and policies of
                                  ASMOF NT as amended from time to time.
                                </strong>
                              </p>
                            </div>
                          </div>
                        )}

                        {profile.ChapterCode === "TAS" &&
                          (profile.stage === "dit" ||
                            (profile.stage === "dr" &&
                              values.CRAFT_GROUP !== "GENP")) && (
                            <div className="row">
                              <div className="large-12 columns">
                                <h3>ASMOF</h3>
                              </div>
                              <div className="medium-12 columns">
                                <label>
                                  <Field
                                    type="checkbox"
                                    name="asmof"
                                    checked={values.asmof}
                                  />
                                  <strong>
                                    Opt out. If you wish to opt out and not
                                    become a member of the Australian Salaried
                                    Medical Officers’ Federation Tasmania (ASMOF
                                    TAS), please tick this box.
                                  </strong>
                                </label>
                                <p>
                                  <strong>
                                    Your membership with AMA TAS also includes
                                    membership with ASMOF TAS for no extra fee.
                                    <br />
                                    By completing this online application you
                                    agree to abide by the rules and policies of
                                    ASMOF TAS as amended from time to time.
                                  </strong>
                                </p>
                              </div>
                            </div>
                          )}

                        {Constants.wpissueState.indexOf(profile.ChapterCode) >=
                          0 && (
                          <div className="row">
                            <div className="large-12 columns">
                              <h3>
                                Do you have an ongoing or pre-existing workplace
                                issue?{" "}
                                <small className="form-error is-visible">
                                  {touched.wpissue && errors.wpissue}
                                </small>
                              </h3>
                              <p>
                                Please be aware that if you have an ongoing or
                                pre-existing issue AMA{" "}
                                {Constants.stateLookup[profile.ChapterCode]}{" "}
                                reserves the right to determine the level of
                                support they can provide for you.
                              </p>
                            </div>
                            <div className="medium-12 columns">
                              <label>
                                <Field name="wpissue" component="select">
                                  <option value="">--Select--</option>
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Field>
                              </label>
                            </div>
                          </div>
                        )}

                        {Constants.mgmState.indexOf(profile.ChapterCode) >=
                          0 && (
                          <div className="row">
                            <div className="large-12 columns">
                              <h3>Member Get A Member</h3>
                              <p>
                                AMA {Constants.stateLookup[profile.ChapterCode]}
                                ’s Member Get a Member Program offers an
                                outstanding financial incentive for existing
                                members to introduce new doctors to join AMA{" "}
                                {Constants.stateLookup[profile.ChapterCode]}.
                                Receive a 25% discount on next year’s membership
                                for each new member you introduce. Have you been
                                referred by a member or would you like to refer
                                a colleague? Let us know who so we can apply the
                                discount!
                              </p>
                            </div>
                            <div className="large-12 columns">
                              <label htmlFor="referral">
                                <div>Name of member who referred you</div>
                                <Field type="text" name="referral" />
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="row">
                          <div className="large-6 columns">
                            <NavLink
                              to="/join-member"
                              className="button expanded success"
                            >
                              Back
                            </NavLink>
                          </div>
                          <div className="large-6 columns">
                            <button
                              type="submit"
                              className="button expanded success"
                              disabled={isSubmitting}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row margin-top">
        <div className="large-12 columns">
          <a href="/clear" className="button">
            Clear Form
          </a>
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;
