import React, { useState, useEffect, useRef} from 'react';
import { Field, FieldArray } from 'formik';
import * as Constants from '../config';
import {getOptions, keyArrayToObjArray} from '../config';
//import Script from 'react-load-script';

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
    var aus = new window.google.maps.Circle({ center: new window.google.maps.LatLng(-27.470125, 153.021072), radius: 32000 });

    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ["geocode"], bounds: aus.getBounds(), }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    );
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
}

function AddressFields(props) {
    // const [query, setQuery] = useState("");
    // const autoCompleteRef = useRef(null);
    //
    // useEffect(() => {
    //     loadScript(
    //         `https://maps.googleapis.com/maps/api/js?key=AIzaSyD4WRU6ZrTrDzeLO-_9ic9mBPnYeDN2IVg&libraries=places`,
    //         () => handleScriptLoad(setQuery, autoCompleteRef)
    //     );
    // }, []);

    //const values = props.values;
    const type = props.type;
    const errors = props.errors;
    const touched = props.touched;
    return (
            <div>
                <div className="row">
                    {/*<FieldArray*/}
                        {/*name={type}*/}
                        {/*render={arrayHelpers => (*/}
                            {/*<div>*/}
                                {/*<div className="large-4 columns">*/}
                                    {/*<label htmlFor="address1">*/}
                                        {/*<div>Address line 1 <small className="form-error is-visible">{touched.address1 && errors.address1}</small></div>*/}
                                        {/*<Field name={'type[${type}].a1'} type="text" />*/}
                                    {/*</label>*/}
                                {/*</div>*/}
                                {/*<div className="large-4 columns">*/}
                                    {/*<label htmlFor="address2">*/}
                                        {/*<div>Address line 2</div>*/}
                                        {/*<Field name="type[${type}].a2" type="text"/>*/}
                                    {/*</label>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*)}*/}
                    {/*/>*/}

                    <div className="large-4 columns">
                        <label htmlFor="address1">
                            <div>Address line 1 <small className="form-error is-visible">{errors[type]?errors[type].address1:''}</small></div>
                            <Field name={`${type}.address1`} type="text" />
                        </label>
                    </div>
                    <div className="large-4 columns">
                        <label htmlFor="address2">
                            <div>Address line 2</div>
                            <Field name={`${type}.address2`} type="text"/>
                        </label>
                    </div>
                    <div className="large-4 columns">
                        <label htmlFor="address3">
                            <div>Address line 3</div>
                            <Field name={`${type}.address3`} type="text"/>
                        </label>
                    </div>

                </div>
                <div className="row">
                    <div className="large-4 columns">
                        <label htmlFor="suburb">
                            <div>Suburb <small className="form-error is-visible">{errors[type]?errors[type].suburb:''}</small></div>
                            <Field name={`${type}.suburb`} type="text"/>
                        </label>
                    </div>
                    <div className="large-4 columns">
                        <label htmlFor="state">
                            <div>State <small className="form-error is-visible">{errors[type]?errors[type].state:''}</small></div>
                            <Field name={`${type}.state`} component="select">
                                {getOptions(Constants.stateList)}
                                {/*<option value="ACT">Australian Capital Territory</option>*/}
                                {/*<option value="NSW">New South Wales</option>*/}
                                {/*<option value="NT">Northern Territory</option>*/}
                                {/*<option value="QLD">Queensland</option>*/}
                                {/*<option value="SA">South Australia</option>*/}
                                {/*<option value="TAS">Tasmania</option>*/}
                                {/*<option value="VIC">Victoria</option>*/}
                                {/*<option value="WA">Western Australia</option>*/}
                            </Field>
                        </label>
                    </div>
                    <div className="large-4 columns">
                        <label htmlFor="postcode">
                            <div>Postcode <small className="form-error is-visible">{errors[type]?errors[type].postcode:''}</small></div>
                            <Field name={`${type}.postcode`} type="text" maxLength="4"/>
                        </label>
                    </div>
                </div>
            </div>
        );
}

export default AddressFields;