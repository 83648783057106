import React, {useState, useEffect} from 'react';
import { Formik, Field } from 'formik';
import * as Constants from './config';
import {getOptions, keyArrayToObjArray} from './config';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SignupMemberForm from './signup/index';
import {careerStage} from "./config";
import {prefixLookup} from "./config";
import { string, date, object, number } from 'yup';
import Header from "./Header";
import NameHeader from "./signup/NameHeader";
import { parse, isDate } from "date-fns";
import qs from "qs";


function parseDateString(value, originalValue) {
    //console.log(parse("31/12/2011","dd/MM/yyyy",new Date()))
    //return parse("31/12/2011","dd/MM/yyyy",new Date());
    const parsedDate = isDate(value)
        ? value
        : parse(originalValue, "dd/MM/yyyy", new Date());

    return parsedDate;
}

function SignupMember(props) {
    localStorage.removeItem('tname');
    localStorage.removeItem('page');
    const qstate = qs.parse(props.location.search, { ignoreQueryPrefix: true }).state;
    const qcareer = qs.parse(props.location.search, { ignoreQueryPrefix: true }).career;
    const stateList = ['ACT', 'NSW', 'QLD', 'VIC', 'NT', 'TAS', 'WA', 'SA'];
    const stageList = ['dit', 'student', 'dr'];
    const profile = localStorage.getItem('profile')?JSON.parse(localStorage.getItem('profile')):{};
    if (qstate !== undefined && stateList.includes(qstate)) {profile.ChapterCode = qstate;}
    if (qcareer !== undefined && stageList.includes(qcareer)) {profile.stage = qcareer;}
    const imisid = localStorage.getItem('imisid')?localStorage.getItem('imisid'):null;
    const rid = localStorage.getItem('rid')?localStorage.getItem('rid'):0;
    const history = useHistory();
    const [userState, setUserState] = useState(profile.ChapterCode);
    if (qstate === 'SA' || qstate === 'NSW') {
        window.location='https://www.ama.com.au/membership';
    }
    window['Zuko'].trackForm({target:document.body,slug:'96e47610cd3483b2'}).trackEvent(window['Zuko'].FORM_VIEW_EVENT);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div>
            <Header userState = {userState} />
        <section className="login-container" data-equalizer="foo">
            <div className="row collapse">
                <div className="large-12 columns">
                    <div className="feeslist-subscribers ">
                        <NameHeader profile={profile} imisid={imisid} />

                        <div className="panel-table">
                            <div className="form-container">
                                <Formik
                                    initialValues={{
                                        ChapterCode: profile.ChapterCode?profile.ChapterCode:'',
                                        stage: profile.stage?profile.stage:'',
                                        Prefix: profile.prefixKey,
                                        Firstname: profile.Firstname?profile.Firstname:'',
                                        MiddleName: profile.MiddleName?profile.MiddleName:'',
                                        Lastname: profile.Lastname?profile.Lastname:'',
                                        EmailAddress: profile.EmailAddress?profile.EmailAddress:'',
                                        MobilePhone: profile.MobilePhone?profile.MobilePhone:'',
                                        DateOfBirth: profile.DateOfBirth?profile.DateOfBirth:'',
                                        Username: profile.Username?profile.Username:'',
                                        Password: '',
                                        Password_confirm: '',
                                        ATSI: profile.ATSI?profile.ATSI:'',
                                        Gender: profile.Gender?profile.Gender:'',
                                        //address1: profile.home?profile.home.address1:'',
                                        //address2: profile.home?profile.home.address2:'',
                                        //address3: profile.home?profile.home.address3:'',
                                        //suburb: profile.home?profile.home.suburb:'',
                                        //state: profile.home?profile.home.state:'',
                                        //postcode: profile.home?profile.home.postcode:'',
                                        MED_SCHOOL: profile.MED_SCHOOL?profile.MED_SCHOOL:'',
                                        CategoryCode: profile.CategoryCode?profile.CategoryCode:'',
                                        YR_MED_SCHOOL: profile.YR_MED_SCHOOL?profile.YR_MED_SCHOOL:'',
                                        YR_GRAD: profile.YR_GRAD?profile.YR_GRAD:'',
                                        TRAINING_NETWORK: profile.TRAINING_NETWORK?profile.TRAINING_NETWORK:'',
                                        recaptcha: '',
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validationSchema={object().shape({
                                        Prefix: string().required('Required'),
                                        Firstname: string().required('Required'),
                                        Lastname: string().required('Required'),
                                        MobilePhone: number('must be numbers').required('Required'),
                                        EmailAddress: string().email('is not valid').required('Required'),
                                        //YR_MED_SCHOOL: object().when('stage', {
                                        //    is: 'student',
                                        //    then: number().required('is requireddd'),
                                        //    otherwise: number()
                                        //}),
                                        DateOfBirth: date().transform(parseDateString)
                                            .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'is not valid. You must be at least 18 years old')
                                            .min(new Date(new Date().setFullYear(new Date().getFullYear() - 80)), 'is not valid. Please check.')
                                            .required('Required'),
                                        recaptcha: string().required('Please confirm that you are not a robot'),
                                        //TRAINING_NETWORK: string('is required').nullable().required('is required'),
                                    })}
                                    validate={values => {
                                        let errors = {};
                                        //if (!values.EmailAddress) {
                                        //    errors.EmailAddress = 'Required';
                                        //} else if (
                                        //    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.EmailAddress)
                                        //) {
                                        //    errors.EmailAddress = 'Invalid email address';
                                       // }
                                        if (!values.ChapterCode) {errors.ChapterCode = 'Required';}
                                        //if (!values.DateOfBirth) {errors.DateOfBirth = 'Required';} else {
                                        //    let dd = new Date(values.DateOfBirth);
                                        //    let now = new Date();
                                        //    let yearDiff = now.getFullYear() - dd.getFullYear();
                                        //    if (yearDiff < 18 || yearDiff >70) {
                                        //        errors.DateOfBirth = 'Your Date of birth is invalid';
                                        //    }
                                        //}

                                        if (!values.Username) {errors.Username = 'Required';}

                                        //if (rid===0 || values.Password) {
                                            if (!values.Password) {
                                                errors.Password = 'Required';
                                            } else if (!values.Password_confirm) {
                                                errors.Password_confirm = 'Please confirm your password';
                                            } else if (values.Password !== values.Password_confirm) {
                                                errors.Password = 'Password does not match';
                                            } else if (!/^((?=.*[a-z])(?=.*\d)|(?=.*[A-Z])(?=.*\d)).{7,32}$/i.test(values.Password)) {
                                                errors.Password = 'Password does not meet our password policy. Must be combination of letters and at least 1 number at minimum 7 characters.';
                                            }
                                        //}

                                        if (values.stage === 'student') {
                                            if (!values.MED_SCHOOL) {errors.MED_SCHOOL = 'Required';}
                                            if (!values.CategoryCode) {errors.CategoryCode = 'Required';}
                                            if (!values.YR_MED_SCHOOL) {errors.YR_MED_SCHOOL = 'Required';}
                                            if (!values.YR_GRAD) {errors.YR_GRAD = 'Required';}
                                        }
                                        // else {
                                        //     if (!values.address1) {errors.address1 = 'Required';}
                                        //     if (!values.state) {errors.state = 'Required';}
                                        //     if (!values.suburb) {errors.suburb = 'Required';}
                                        //     if (!values.postcode) {errors.postcode = 'Required';}
                                        // }
                                        //if (!values.recaptcha) {errors.recaptcha = 'Please confirm that you are not a robot';}
                                        //setErrors(errors);
                                        //if (Object.keys(errors).length) {
                                        console.log(Object.keys(errors).length);
                                        return Object.keys(errors).length?errors:axios.get(Constants.apiurl+'/api/user/join/validate/' + values.EmailAddress + '/' + values.Username)
                                            .then((res) => {
                                                //let errors = {};
                                                console.log(JSON.stringify(res.data));
                                                //console.log(this.state);
                                                if (!res.data.isUsernameOk) {
                                                    errors.Username = 'is already in use';
                                                    //errors.VALIDATION_RESULT = res.data.accessLevel;
                                                }
                                                if (!res.data.isEmailOk) {
                                                    errors.EmailAddress = 'is already in use';
                                                    //actions.setState({joinValidation})
                                                }
                                                if (Object.keys(errors).length) {
                                                    return errors;
                                                    //
                                                }
                                            })
                                            .catch(()=>{
                                                errors.VALIDATION = 'Network exception, please try again later';
                                                console.log("blocked?");
                                                //setErrors(errors);
                                                //setSubmitting(false);
                                                return errors;
                                            });
                                    }}
                                    onSubmit={(values, { setSubmitting, setErrors, props }) => {
                                        let errors = {};
                                        let mdata = Object.assign(profile, values);
                                        mdata.prefixKey = profile.Prefix;
                                        mdata.Prefix = prefixLookup[profile.Prefix];
                                        mdata.iMISID = localStorage.getItem('imisid')?localStorage.getItem('imisid'):0;
                                        mdata.rid = localStorage.getItem('rid')?localStorage.getItem('rid'):0;
                                        //mdata.home = {};
                                        //mdata.home = (({ address1, address2, address3, suburb, state, postcode, country }) => ({ address1, address2, address3, suburb, state, postcode, country }))(values);
                                        console.log(JSON.stringify(mdata, null, 2));
                                        localStorage.setItem('profile', JSON.stringify(mdata, null, 2));

                                        if (values.stage === 'student') {
                                            axios.post(Constants.apiurl+'/api/user/join/post-student', {mdata})
                                                .then(res=>{
                                                    console.log('getting sth');
                                                    //console.log(res);
                                                    console.log(res);
                                                    if (res.data.success) {
                                                        localStorage.setItem('tname', values.Firstname);
                                                        localStorage.setItem('page', res.data.page);
                                                        localStorage.removeItem('profile');
                                                        //window.location = '/join-student/confirm';
                                                        history.push("/join-student/confirm")
                                                    } else {
                                                        console.log('wth'+res.data);
                                                        if (res.data.imisid !== 0) {
                                                            localStorage.setItem('imisid', res.data.imisid);
                                                        }
                                                        if (res.data.rid !== 0) {
                                                            localStorage.setItem('rid', res.data.rid);
                                                        }
                                                        errors.VALIDATION = res.data.message;
                                                        setErrors(errors);
                                                    }

                                                    setSubmitting(false);
                                                })
                                                .catch(()=>{
                                                    errors.VALIDATION = 'Server exception, please try again later';
                                                    console.log("blocked?");
                                                    setErrors(errors);
                                                    setSubmitting(false);
                                                })
                                        } else {
                                            if (mdata.rid===0) {
                                                axios.post(Constants.apiurl+'/api/user/join/post', {mdata})
                                                    .then(res=>{
                                                        console.log('getting sth');
                                                        console.log(res);
                                                        console.log(res.data);
                                                        localStorage.setItem('imisid', res.data.imisid);
                                                        localStorage.setItem('rid', res.data.rid);

                                                        if (res.data.success) {
                                                            //window.location = '/join-member/next';
                                                            history.push("/join-member/next")
                                                        } else {
                                                            errors.VALIDATION = res.data.message;
                                                            setErrors(errors);
                                                        }
                                                        setSubmitting(false);
                                                    })
                                                    .catch(()=>{
                                                        errors.VALIDATION = 'Server exception, please try again later';
                                                        console.log("blocked?");
                                                        setErrors(errors);
                                                        setSubmitting(false);
                                                    })
                                            } else {
                                                console.log('skipping saving');
                                                history.push("/join-member/next")
                                            }
                                        }
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="medium-6 columns">
                                                    <label htmlFor="ChapterCode">
                                                        <div>State or Territory <small
                                                            className="form-error is-visible">{touched.ChapterCode && errors.ChapterCode}</small></div>
                                                        <Field
                                                            name="ChapterCode" component="select"
                                                            onChange={(e) => {
                                                                if (values.state === '') {
                                                                    setFieldValue('state', e.target.value);
                                                                }
                                                                setFieldValue('ChapterCode', e.target.value);
                                                                setUserState(e.target.value);
                                                            }
                                                            }
                                                        >
                                                            {getOptions(Constants.stateList)}
                                                        </Field>
                                                    </label>
                                                </div>

                                                {values.ChapterCode!=='' && <div className="medium-6 columns">
                                                    <label htmlFor="stage">
                                                        <div>Career Stage <small
                                                            className="form-error is-visible">{touched.stage && errors.stage}</small></div>
                                                        <Field name="stage" component="select">
                                                            <option value="">--Select--</option>
                                                            {getOptions(keyArrayToObjArray(careerStage[values.ChapterCode], Constants.careerStageLookup[values.ChapterCode]))}
                                                        </Field>
                                                    </label>
                                                </div>}

                                            </div>
                                            <SignupMemberForm
                                                errors = {errors}
                                                touched = {touched}
                                                values = {values}
                                                handleChange = {handleChange}
                                                handleBlur = {handleBlur}
                                                handleSubmit = {handleSubmit}
                                                isSubmitting = {isSubmitting}
                                                setFieldValue = {setFieldValue}
                                            />

                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <div className="row margin-top">
                <div className="large-12 columns">
                    <a href="/clear" className="button">Clear Form</a>
                </div>
            </div>
        </div>
    );
}

export default SignupMember;
