import React from 'react';
import { Field } from 'formik';

function StudentFields(props) {
    //const values = props.values;
    const errors = props.errors;
    const touched = props.touched;
    return (
        <div>
            <div className="row">
                {/*<div className="large-12 columns">*/}
                    {/*<h3>University Information</h3>*/}
                {/*</div>*/}
                <div className="large-8 columns">
                    <label htmlFor="MED_SCHOOL">
                        <div>University <small className="form-error is-visible">{touched.MED_SCHOOL && errors.MED_SCHOOL}</small></div>
                        <Field name="MED_SCHOOL" component="select">
                            <option value={null}>--Select--</option>
                            <option value="ANU">Australian National University</option>
                            <option value="BOND">Bond University</option>
                            <option value="CURTIN">Curtin University</option>
                            <option value="DEAKIN">Deakin University</option>
                            <option value="FLINDERS">Flinders University</option>
                            <option value="GRIFFITH">Griffith University</option>
                            <option value="JCU">James Cook University</option>
                            <option value="MONASH">Monash University</option>
                            <option value="MURDOCH">Murdoch University</option>
                            <option value="MQ">Macquarie University</option>
                            <option value="ADELAIDE">The University of Adelaide</option>
                            <option value="MELBOURNE">The University of Melbourne</option>
                            <option value="UNE">The University of New England</option>
                            <option value="UNSW">The University of New South Wales</option>
                            <option value="NEWCASTLE">The University of Newcastle</option>
                            <option value="UNDF">The University of Notre Dame (Fremantle)</option>
                            <option value="UNDS">The University of Notre Dame (Sydney)</option>
                            <option value="UQ">The University of Queensland</option>
                            <option value="USYD">The University of Sydney</option>
                            <option value="UTAS">The University of Tasmania</option>
                            <option value="UWA">The University of Western Australia</option>
                            <option value="WSYD">The University of Western Sydney</option>
                            <option value="WOLLON">The University of Wollongong</option>
                            <option value="OTHER">Other...</option>
                        </Field>
                    </label>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="CategoryCode">
                        <div>Current year of study <small className="form-error is-visible">{touched.CategoryCode && errors.CategoryCode}</small></div>
                        <Field name="CategoryCode" component="select">
                            <option value={null}>--Select--</option>
                            <option value="F1S1">First year</option>
                            <option value="F2S1">Second year</option>
                            <option value="F3S1">Third year</option>
                            <option value="F4S1">Fourth year</option>
                            <option value="F5S1">Fifth year</option>
                            <option value="F6S1">Sixth year</option>
                        </Field>
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="large-4 columns">
                    <label htmlFor="YR_MED_SCHOOL">
                        <div>Commencement year <small className="form-error is-visible">{touched.YR_MED_SCHOOL && errors.YR_MED_SCHOOL}</small></div>
                        <Field type="number" min="2000" max="3000"  name="YR_MED_SCHOOL" maxLength="4"/>
                    </label>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="YR_GRAD">
                        <div>Expected Graduation Year <small className="form-error is-visible">{touched.YR_GRAD && errors.YR_GRAD}</small></div>
                        <Field type="number" min="2000" max="3000" name="YR_GRAD" maxLength="4"/>
                    </label>
                </div>
                <div className="large-4 columns">
                    <label htmlFor="TRAINING_NETWORK">
                        <div>Post-graduation field of interest <small
                            className="form-error is-visible">{errors.TRAINING_NETWORK && touched.TRAINING_NETWORK && errors.TRAINING_NETWORK}</small>
                        </div>
                        <Field name="TRAINING_NETWORK" component="select">
                            <option value={null}>--Select--</option>
                            <option value="MALC">Alcohol & Drug Depd'cy/Rehab</option>
                            <option value="AANA">Anaesthesia</option>
                            <option value="AANI">Anaesthetics (Intensive Care)</option>
                            <option value="PANT">Anatomical Pathology</option>
                            <option value="MAVI">Aviation Medicine</option>
                            <option value="SBRE">Breast Surgery</option>
                            <option value="SCTH">Cardio-Thoracic Surgery</option>
                            <option value="MCAR">Cardiology</option>
                            <option value="OCMO">Career Medical Officer</option>
                            <option value="PCHE">Chemical Pathology</option>
                            <option value="LCHI">Child Psychiatry</option>
                            <option value="MCLH">Clinical Haematology</option>
                            <option value="MCLP">Clinical Pharmacology</option>
                            <option value="SCOL">Colorectal Surgery</option>
                            <option value="COSM">Cosmetic Surgery</option>
                            <option value="DDER">Dermatology</option>
                            <option value="RRAD">Diagnostic Radiology</option>
                            <option value="RULT">Diagnostic Ultrasound</option>
                            <option value="ODIT">Doctor In Training</option>
                            <option value="EMED">Emergency Medicine</option>
                            <option value="MEND">Endocrinology</option>
                            <option value="SENT">Ent-Head & Neck Surgery</option>
                            <option value="FFAM">Family Medicine</option>
                            <option value="PFOR">Forensic Pathology</option>
                            <option value="MGAS">Gastroenterology</option>
                            <option value="GGPR">General Practice</option>
                            <option value="MGEN">Genetics</option>
                            <option value="MGER">Geriatric Medicine</option>
                            <option value="GERI">Geriatrics</option>
                            <option value="BGON">Gynaecological Oncology</option>
                            <option value="BGYN">Gynaecology</option>
                            <option value="PHAE">Haematology</option>
                            <option value="SHAN">Hand Surgery</option>
                            <option value="ZEDU">Hlth/Med Ed/Research</option>
                            <option value="MIMM">Immunology/Allergy</option>
                            <option value="MINF">Infectious Diseases</option>
                            <option value="EINC">Intensive Care</option>
                            <option value="MINT">Internal Medicine</option>
                            <option value="MAXI">MAXILLO-FACIAL SURGERY</option>
                            <option value="ZADM">Medical Administration</option>
                            <option value="MONC">Medical Oncology</option>
                            <option value="MEGE">Medicine General</option>
                            <option value="ZMLC">Medico-Legal Consulting</option>
                            <option value="PMIB">Microbiology</option>
                            <option value="SMIC">Microsurgery</option>
                            <option value="MMUS">Musculoskeletal Medicine</option>
                            <option value="NEPH">NEPHROLOGY</option>
                            <option value="MNEO">Neonatal Medicine</option>
                            <option value="NEON">Neonatology</option>
                            <option value="MNEU">Neurology</option>
                            <option value="SNEU">Neurosurgery</option>
                            <option value="ZZNA">Not Applicable</option>
                            <option value="NNPP">Not Practising</option>
                            <option value="MNUC">Nuclear Medicine</option>
                            <option value="ONCO">ONCOLOGY</option>
                            <option value="BGYO">Obstetrics & Gynaecology</option>
                            <option value="MOCC">Occupational Medicine</option>
                            <option value="SOPT">Ophthalmology</option>
                            <option value="SOMX">Oral/Maxillofacial Surgery</option>
                            <option value="SORT">Orthopaedic Surgery</option>
                            <option value="BOTO">Otolaryngology</option>
                            <option value="BOTH">Otorhinolaryngology</option>
                            <option value="MPAE">Paediatric Medicine</option>
                            <option value="PANE">Paediatric Neurology</option>
                            <option value="PAON">Paediatric Oncology</option>
                            <option value="SPAE">Paediatric Surgery</option>
                            <option value="PPMN">Pain Management</option>
                            <option value="MPAL">Palliative Medicine</option>
                            <option value="PPAT">Pathology</option>
                            <option value="ZPHC">Pharmacology</option>
                            <option value="MPHM">Physical Medicine</option>
                            <option value="MPHY">Physician</option>
                            <option value="SPLA">Plastic & Reconst Surgery</option>
                            <option value="LPSY">Psychiatry</option>
                            <option value="FPUB">Public Health</option>
                            <option value="RONC">Radiation Oncology</option>
                            <option value="RRAO">Radiology</option>
                            <option value="RADI">Radiology Diagnostic</option>
                            <option value="MREH">Rehabilitation</option>
                            <option value="RREH">Rehabilitation Medicine</option>
                            <option value="MREN">Renal Medicine</option>
                            <option value="MREM">Respiratory Medicine</option>
                            <option value="MRHE">Rheumatology</option>
                            <option value="MVEN">Sexual Health</option>
                            <option value="MSPO">Sports Medicine</option>
                            <option value="SSUR">Surgery - General</option>
                            <option value="SUGC">Surgery Consultant</option>
                            <option value="MTHO">Thoracic Medicine</option>
                            <option value="SURO">Urology</option>
                            <option value="SVAS">Vascular Surgery</option>
                        </Field>
                    </label>
                </div>
            </div>
        </div>

    );
}

export default StudentFields;